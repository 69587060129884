import * as React from "react";
import styled from "styled-components";
import BigLift from "../static/BigLift2.gif"
import "@fontsource/caesar-dressing";
import "@fontsource/prompt";



// styles
const Header = styled.h1` 
  color: white;
  text-align: center;
  font-size: 72px;
  font-family: "Caesar Dressing";
  margin: 0px;
`;

const Text = styled.h2`
  color: white;
  font-size: 100px;
  font-family: "Caesar Dressing";
  font-weight: 200;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
`;

const Link = styled.a`
  color: white;
  display: flex;
  justify-content: left;
  align-items: center;
`

const LinkBox = styled.div`
  display: flex;
  justify-content: space-around;
`

const LiftStyle = styled.img`
  margin-left: auto;
  margin-right: auto;
`

// markup
const IndexPage = () => {
  return (
    <main>
      <Header>WILL BARON: PERSONAL TRAINER</Header>
      <Box>
        <LiftStyle src={BigLift} height="600" width="800" />
        <Text>STOP BEING SMALL</Text>
      </Box>
      <LinkBox>
        <Link href="/about" >ABOUT</Link>
        <Link href="/videos" >MORE VIDEOS</Link>
        <Link href="/contact" >CONTACT</Link>
      </LinkBox>
    </main>
  )
}

export default IndexPage
